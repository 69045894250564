<template>
  <div class="page-wrapper">
    <base-panel
      :title="pageTitle"
      :is-add-button-visible="false"
    />

    <div class="billing-filter-container">
      <div>
        <div class="base-card">
          <div class="billing-form-wrapper">
            <div class="form-item-group">
              <div class="form-item">
                <div class="form-item__name">Балансовые группы</div>
                <div class="form-item__value">
                  <v-select
                    v-model="filter.groups"
                    :options="sortGroups(getGroups)"
                    :reduce="group => group.id"
                    multiple
                    :get-option-label="(option) => option.attributes.name"
                    :disabled="filter.zones.length !== 0"
                    placeholder="Выберите из списка"
                    class="vue-select-input"
                  />
                </div>
              </div>

              <div class="form-item">
                <div class="form-item__name">Зоны</div>
                <div class="form-item__value">
                  <v-select
                    v-model="filter.zones"
                    :options="sortGroups(getBalanceGroupsByZones)"
                    :reduce="zone => zone.id"
                    multiple
                    :get-option-label="(option) => option.attributes.name"
                    :disabled="filter.groups.length !== 0"
                    placeholder="Выберите из списка"
                    class="vue-select-input"
                  />
                </div>
              </div>
            </div>

            <div class="form-item-group">
              <div class="form-item">
                <div class="form-item__name">Период</div>
                <div class="form-item__value">
                  <date-picker
                    id="period"
                    v-model="filter.period"
                    :format="'MMMM YYYY г.'"
                    type="month"
                    placeholder="Выберите период"
                    class="base-date-picker"
                    multiple
                    value-type="YYYY-MM-DD"
                    :default-value="defaultValue"
                    :disabled-date="notAfterToday"
                  />
                </div>
              </div>

              <div class="form-item">
                <div class="form-item__name">Группировка</div>
                <div class="form-item__value">
                  <v-select
                    v-model="grouping"
                    :options="groupingList"
                    :reduce="group => group.id"
                    :get-option-label="(option) => option.label"
                    placeholder="Выберите из списка"
                    class="vue-select-input"
                  />
                </div>
              </div>
            </div>

            <button
              class="button button--mode-primary"
              @click="submitFilter()"
            >
              Сгруппировать
              <base-loader
                v-if="loading"
                color-class="color-white"
              />
            </button>
          </div>
        </div>

        <balance-result
          :filter="this.filter"
          :isVs="this.$route.name === 'WaterBalance'"
        />
      </div>

      <balance-map
        :balance_zones_ids="filter.zones"
        :balance_groups_ids="filter.groups"
        :periods="filter.period"
      />
    </div>
  </div>
</template>

<script>
import BalanceMap from "@/components/Billing/BalanceMap";
import {mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import BalanceResult from "@/components/Billing/BalanceResult";
import BaseLoader from "@/layout/BaseLoader";
import BasePanel from "@/components/BasePanel.vue";
import {showAlert} from "@/utils/notification";

export default {
  name: "BalancePage",
  components: {BasePanel, BaseLoader, BalanceResult, BalanceMap, DatePicker},
  data() {
    return {
      isVS: true,
      isZones: false,
      filter: {
        groups: [],
        zones: [],
        period: null
      },
      defaultValue: new Date(),
      groupingList: [
        {id: 0, label: 'Общая'},
        {id: 1, label: 'По месяцам'}
      ]
    }
  },
  computed: {
    ...mapGetters({
      getBalanceGroupsByZones: 'balanceGroup/getBalanceGroupsByZones',
      getGroups: 'balanceGroup/getBalanceGroupsForTotal',
      getZone: 'zone/getZone',
      loading: 'balance/loading'
    }),
    pageTitle() {
      return this.isVS ? 'Баланс водоснабжения' : 'Баланс водоотведения'
    },
    grouping: {
      get() {
        return this.$store.getters['balance/grouping'];
      },
      set(grouping) {
        this.$store.commit('balance/SET_GROUPING', grouping);
      }
    },
    sortGroups: function () {
      return groups => {
        let sorted_groups = []

        if (this.$route.name === 'WaterBalance') {
          sorted_groups = groups.filter(group => group.attributes.network === 1);
        } else if (this.$route.name === 'DrainBalance') {
          sorted_groups = groups.filter(group => group.attributes.network === 2);
        }

        return sorted_groups
      }
    }
  },
  watch: {
    $route() {
      this.isVS = this.$route.name === 'WaterBalance';
      this.filter.groups = []
      this.filter.zones = []
      this.filter.period = null
      this.grouping = null

      this.$store.commit('balance/CLEAR_STATE');
    }
  },
  beforeDestroy() {
    this.$store.commit('balance/CLEAR_STATE');
  },
  mounted() {
    this.$store.dispatch("zone/getAllZones");
    this.$store.dispatch("balanceGroup/getAllBalanceGroups");
    this.$store.dispatch('device/getAllDevices');

    this.isVS = this.$route.name === 'WaterBalance';
  },
  methods: {
    submitFilter() {
      if (this.filter.groups.length === 0 && this.filter.zones.length === 0) {
        showAlert('Ошибка', 'Выберите балансовую группу или зону', 'warning');
        return false;
      } else if (this.filter.period === null) {
        showAlert('Ошибка', 'Выберите периоды', 'warning');
        return false;
      } else if (this.grouping === null) {
        showAlert('Ошибка', 'Выберите группировку', 'warning');
        return false;
      }

      let payload
      if (this.filter.zones.length !== 0) {
        payload = {
          params: {
            periods: this.filter.period,
            groups: this.filter.zones
          },
          grouping: this.grouping
        }
        this.isZones = true
      } else if (this.filter.groups.length !== 0) {
        payload = {
          params: {
            periods: this.filter.period,
            groups: this.filter.groups
          },
          grouping: this.grouping
        }
        this.isZones = false
      }

      if (this.isVS) {
        this.$store.dispatch('balance/getSupplyBalance', payload);
      } else {
        this.$store.dispatch('balance/getDrainBalance', payload);
      }
    },
    notAfterToday(date) {
      let lastDate = new Date();
      lastDate.setDate(1);
      lastDate.setMonth(lastDate.getMonth() - 1);
      return date > lastDate;
    }
  }
}
</script>

<style scoped>
</style>