<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table--appeals">
        <div class="base-table__header-cell">ID</div>
        <div @click="isAscSort = !isAscSort; sortAppeals()"
             class="base-table__header-cell base-table__header-cell-sort"
             :class="{'toggled': !isAscSort}">
          Дата
          <icon-sort/>
        </div>
        <div class="base-table__header-cell">Проблема</div>
        <div class="base-table__header-cell">Адрес</div>
        <div class="base-table__header-cell">Источник</div>
        <div class="base-table__header-cell">Статус</div>
        <div class="base-table__header-cell">Заявка</div>
        <div class="base-table__header-cell">Подразделение</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="appeal in appealsList"
           :key="appeal.id"
           class="base-table__row appeal-item base-table--appeals"
           @click="openAppealModal(appeal.id)">
        <div class="base-table__column base-table__column--number">
          <div class="base-table__num">{{ appeal.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ formatDateTime(appeal.created_at) }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text base-table__tooltip-wrapper base-table__indicator">
            <span class="base-table__indicator-circle"
                  :style="{'background-color': appeal.category.group_color}"/>
            {{ appeal.category.name }}

            <div class="base-table__tooltip">
              {{appeal.category.group_name}}
            </div>
          </div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ appeal.getFullAddress() }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ appeal.getSourceLabel() }}</div>
        </div>
        <div class="base-table__column base-table__column--state">
          <div class="base-table__tag"
               :class="[
                 {'base-table__tag--new': appeal.state === AppealState.NEW},
                 {'base-table__tag--work': appeal.state === AppealState.IN_WORK},
                 {'base-table__tag--done': appeal.state === AppealState.CLOSED},
                 {'base-table__tag--canceled': appeal.state === AppealState.CANCELED}
               ]">
            {{ appeal.getStateLabel() }}
          </div>
        </div>
        <div class="base-table__column">
          <div v-if="appeal.issue_id"
               @click.stop="openIssueModal(appeal.issue_id)"
               class="base-table__join-link">
            <icon-link/>
            <div class="base-table__join-counter">#{{appeal.issue_id}}</div>
          </div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ appeal.structuralUnit.name }}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <span v-if="appeal.message"
                class="base-table__short-info">
            <icon-comment class="base-table__short-info-icon"/>
            <span class="base-table__short-info-counter">1</span>

            <div class="base-table__short-info-tooltip">
              <div class="base-table__short-info-tooltip-inner">
                {{ appeal.message }}
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import IconLink from "@/components/Icons/IconLink.vue";
import IconComment from "@/components/Icons/IconComment.vue";
import IconSort from "@/components/Icons/IconSort.vue";
import AppealState from "@/models/callCenter/AppealState";
import sideModalControls from "@/mixins/sideModalControls";
import {formatDateTime} from "@/utils/filters";

export default {
  name: "AppealsList",
  components: {IconSort, IconComment, IconLink},
  mixins: [sideModalControls],
  props: {
    appealsList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isAscSort: true
    }
  },
  computed: {
    AppealState: () => AppealState
  },
  methods: {
    formatDateTime,
    sortAppeals() {
      let sortDir;
      if (this.isAscSort) sortDir = 'asc'
      else sortDir = 'desc'

      this.$store.commit('appeals/SET_FILTER_PARAM', {
        key: 'sort_dir',
        value: sortDir
      })
      this.$store.dispatch('appeals/getAppeals');
    }
  }
}
</script>

<style scoped>
.base-table--appeals {
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
}
</style>
