<template>
  <div
    v-if="dictionary"
    class="page-wrapper">
    <div class="panel">
      <button
        @click="$router.push('/technical-inspection-dictionaries')"
        class="button button--mode-skinny"
      >
        <img
          src="@/assets/img/icons/arrowSquareLeft.svg"
          alt="стрелочка">
        Словари
      </button>

      <base-panel
        :title="dictionary.name"
        :is-add-button-visible="false"/>
    </div>

    <base-functional-line @search-change="event => $store.commit(
      'dictionaries/SET_DICTIONARY_ITEMS_QUERY',event.target.value)">
      <template>
        <button
          @click="openDirectoryItemPopup()"
          class="button button--mode-primary">
          <icon-plus/>
          Добавить значение
        </button>
      </template>
    </base-functional-line>

    <base-content-loaders v-if="loading && !dictionary"/>

    <dictionary-items-list
      v-if="dictionaryItems.length"
      :dictionary-items-list="dictionaryItems"
      @edit-dictionary-item="openDirectoryItemPopup"
      @delete-dictionary-item="deleteDictionaryItem"
    />

    <base-no-data v-else-if="!loading"/>
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import {mapGetters, mapState} from "vuex";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import DictionaryItemsList from "@/components/TechnicalInspection/Dictionary/DictionaryItemsList.vue";
import DictionaryItemPopup from "@/components/TechnicalInspection/Dictionary/DictionaryItemPopup.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import BaseNoData from "@/components/BaseNoData.vue";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";

export default {
  name: "DictionaryPage",
  components: {BaseContentLoaders, BaseNoData, DictionaryItemsList, IconPlus, BaseFunctionalLine, BasePanel},
  data() {
    return {
      popupName: 'dictionary-item-popup'
    }
  },
  computed: {
    ...mapGetters({
      dictionaryItems: 'dictionaries/getDictionaryItemsByQuery'
    }),
    ...mapState({
      dictionary: state => state.dictionaries.dictionary,
      loading: state => state.dictionaries.dictionaryLoading
    })
  },
  mounted() {
    this.$store.dispatch('dictionaries/getDictionary', (this.$route.params.id));
  },
  beforeDestroy() {
    this.$store.commit('dictionaries/SET_DICTIONARY', null);
  },
  methods: {
    openDirectoryItemPopup(dictionaryItem = {}) {
      this.$modal.show(
        DictionaryItemPopup,
        {
          'popupName': this.popupName,
          'dictionaryItem': dictionaryItem,
          'createDictionaryItem': this.createDictionaryItem,
          'updateDictionaryItem': this.updateDictionaryItem
        },
        {
          name: this.popupName,
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    createDictionaryItem(id, payload) {
      this.$store.dispatch('dictionaries/createDictionaryItem', {id, payload})
        .then(() => {
          this.$store.dispatch('dictionaries/getDictionary', id);
          this.$modal.hide(this.popupName);
          showToast('Значение добавлено', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateDictionaryItem(id, itemId, payload) {
      this.$store.dispatch('dictionaries/updateDictionaryItem', {id, itemId, payload})
        .then(() => {
          this.$store.dispatch('dictionaries/getDictionary', id);
          this.$modal.hide(this.popupName);
          showToast('Значение обновлено', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    deleteDictionaryItem(itemId) {
      showConfirmationModal('Вы уверены, что хотите удалить значение из словаря? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('dictionaries/deleteDictionaryItem', {id: this.$route.params.id, itemId})
              .then(() => {
                this.$store.dispatch('dictionaries/getDictionary', this.$route.params.id);
                showToast('Значение удалено', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>