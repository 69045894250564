<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      <template v-if="!Object.keys(category).length">Добавить категорию</template>
      <template v-else>Редактирование категории</template>
      <span @click="$modal.hide('category-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование"
            required
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Группа</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.group_id"
            :options="categoryGroupsList"
            :reduce="group => group.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="checkFormType()"
           class="button">
        Добавить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "CategoryForm",
  components: {IconClose},
  props: {
    categoryGroupsList: {
      type: Array,
      required: true
    },
    category: {
      type: Object,
      default: () => {}
    },
    createCategory: {
      type: Function,
      default: () => {}
    },
    updateCategory: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      payload: {
        name: null,
        group_id: null
      }
    }
  },
  mounted() {
    if (Object.keys(this.category).length) {
      const {name, group} = this.category;
      this.payload.name = name;
      this.payload.group_id = group.id;
    }
  },
  methods: {
    checkFormType() {
      if (Object.keys(this.category).length) {
        this.updateCategory(this.category.id, this.payload);
      } else {
        this.createCategory(this.payload);
      }
    }
  }
}
</script>

<style scoped>

</style>
