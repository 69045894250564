import * as API from './API';
import Complaint from "@/models/TechnicalInspection/Complaint";
import {getError} from "@/utils/helpers";

export default {
    fetchComplaintsList(objectTypeId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/technical-inspection/reference-books/complaints?object_type=${objectTypeId}`)
                .then(response => resolve(response.data.data
                    .map(item => new Complaint({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    createComplaint(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/technical-inspection/reference-books/complaints`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateComplaint(id, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/technical-inspection/reference-books/complaints/${id}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteComplaint(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/technical-inspection/reference-books/complaints/${id}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}