import ComplaintsService from "@/services/ComplaintsService";

export const namespaced = true;

export const state = {
    complaintsList: [],
    loading: false,
    error: null,
    query: ''
}

export const mutations = {
    SET_COMPLAINTS_LIST(state, complaintsList) {
        state.complaintsList = complaintsList;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_QUERY(state, query) {
        state.query = query;
    }
}

export const actions = {
    getComplaintsList({commit}, objectTypeId) {
        commit("SET_LOADING", true);
        ComplaintsService.fetchComplaintsList(objectTypeId)
            .then(complaintsList => commit("SET_COMPLAINTS_LIST", complaintsList))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    createComplaint(context, payload) {
        return new Promise((resolve, reject) => {
            ComplaintsService.createComplaint(payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateComplaint(context, {id, payload}) {
        return new Promise((resolve, reject) => {
            ComplaintsService.updateComplaint(id, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteComplaint(context, id) {
        return new Promise((resolve, reject) => {
            ComplaintsService.deleteComplaint(id)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}

export const getters = {
    getComplaintsListByQuery: state => {
        return state.query.length ? state.complaintsList.filter(complaint => complaint.name.toLowerCase()
            .includes(state.query.toLowerCase())) : state.complaintsList;
    }
}