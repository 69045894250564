<template>
  <base-popup
    :title="popupTitle"
    :name="popupName"
  >
    <template #body>
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            class="base-input base-input--bordered"
            type="text"
            placeholder="Введите наименование">
        </div>
      </div>
    </template>

    <template #footer>
      <button
        @click="submitForm()"
        class="button button--mode-primary">Сохранить</button>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "@/components/BasePopup.vue";

export default {
  name: "ComplaintsForm",
  components: {BasePopup},
  props: {
    popupName: {
      type: String,
      required: true
    },
    complaint: {
      type: Object,
      required: true
    },
    createComplaint: {
      type: Function,
      required: true
    },
    updateComplaint: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      payload: {
        name: null
      }
    }
  },
  computed: {
    isCreateForm() {
      return !Object.keys(this.complaint).length;
    },
    popupTitle() {
      return this.isCreateForm ? 'Добавить жалобу' : 'Редактирование жалобы';
    }
  },
  mounted() {
    if (!this.isCreateForm) {
      const {name} = this.complaint;
      this.payload.name = name;
    }
  },
  methods: {
    submitForm() {
      this.payload.object_type_id = this.$route.params.id;

      if (this.isCreateForm) this.createComplaint(this.payload)
      else this.updateComplaint(this.complaint.id, this.payload)
    }
  }
}
</script>

<style scoped>

</style>