<template>
  <div class="issues-filter__wrapper">
    <div class="issues-filter__buttons-wrapper">
      <div class="filter__button tasks-filter__button"
           :class="{'opened': isFilterModalVisible}"
           @click="isFilterModalVisible = true">
        <icon-settings2/>
        <span>Фильтры</span>
      </div>
      <div v-if="isVisibleChangeViewButtons"
           class="functional-line__view-buttons">
        <div
          @click="$emit('set-list-view', 'list')"
          :class="{'active': issuesView === 'list'}">
          <icon-hamburger-menu/>
        </div>
        <div
          @click="$emit('set-list-view', 'list-group')"
          :class="{'active': issuesView === 'list-group'}">
          <icon-list-group/>
        </div>
        <div
          @click="$emit('set-list-view', 'map')"
          :class="{'active': issuesView === 'map'}">
          <icon-map/>
        </div>
      </div>
      <div v-if="isVisibleCreateIssueButton"
           @click="openCreateIssueModal()"
           class="button button--mode-primary issues-filter__add-btn">
        <icon-plus/>
        <span>Новая заявка</span>
      </div>
    </div>

    <base-filter-modal
      :is-visible="isFilterModalVisible"
      @toggle-modal="isVisible => this.isFilterModalVisible = isVisible">
      <div class="filter__modal-header">
        <div class="filter__modal-name">Фильтры</div>
        <div @click="resetFilters()"
             class="filter__modal-reset">Сбросить все
        </div>
      </div>
      <div class="filter__modal-form">
        <div>
          <label class="input-label">Тип</label>
          <div class="tags-select">
            <div v-for="type in issueTypes"
                 :key="type.id"
                 @click="toggleTypeId(type.id)"
                 class="tags-select__item"
                 :class="{'selected': type.id === typeId}">
              {{type.name}}
            </div>
          </div>
        </div>
        <div>
          <label class="input-label">Категория</label>
          <div class="tags-select">
            <div v-for="group in categoryGroups"
                 :key="group.id"
                 @click="toggleCategoryGroupId(group.id)"
                 class="tags-select__item"
                 :class="{'selected': group.id === categoryGroupId}">
              {{group.name}}
            </div>
          </div>
        </div>
        <div>
          <label class="input-label">Проблема</label>
          <v-select
            class="vue-select-input"
            v-model="categoryId"
            :options="categoriesByGroupId(categoryGroupId)"
            :reduce="category => category.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
      <div class="filter__modal-buttons">
        <div @click="isFilterModalVisible = false"
             class="button button--mode-outline">Закрыть
        </div>
      </div>
    </base-filter-modal>
  </div>
</template>

<script>
import IconSettings2 from "@/components/Icons/IconSettings2";
import IconPlus from "@/components/Icons/IconPlus";
import BaseFilterModal from "@/components/BaseFilterModal";
import IconHamburgerMenu from "@/components/Icons/IconHamburgerMenu";
import IconMap from "@/components/Icons/IconMap";
import {mapGetters, mapState} from "vuex";
import sideModalControls from "@/mixins/sideModalControls";
import IconListGroup from "@/components/Icons/IconListGroup.vue";

export default {
  name: "IssuesListFilter",
  components: {IconListGroup, IconMap, IconHamburgerMenu, BaseFilterModal, IconPlus, IconSettings2},
  mixins: [sideModalControls],
  props: {
    issuesView: {
      type: String
    },
    isVisibleCreateIssueButton: {
      type: Boolean,
      default: false
    },
    isVisibleChangeViewButtons: {
      type: Boolean,
      default: false
    },
    isVisibleClosedDateFilter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFilterModalVisible: false
    }
  },
  computed: {
    typeId: {
      get() {
        return this.typeIdValue
      },
      set(value) {
        this.$store.commit('issues/SET_TYPE_ID', value);
      }
    },
    categoryGroupId: {
      get() {
        return this.categoryGroupIdValue
      },
      set(value) {
        this.$store.commit('issues/SET_CATEGORY_GROUP_ID', value)
      }
    },
    categoryId: {
      get() {
        return this.categoryIdValue
      },
      set(value) {
        this.$store.commit('issues/SET_CATEGORY_ID', value)
      }
    },
    ...mapState({
      issueTypes: state => state.issueTypes.types,
      typeIdValue: state => state.issues.typeId,
      categoryGroupIdValue: state => state.issues.categoryGroupId,
      categoryIdValue: state => state.issues.categoryId
    }),
    ...mapGetters({
      categoriesByGroupId: 'serviceDeskCategories/getCategoriesByGroupId',
      categoryGroups: 'serviceDeskCategories/getGroupsContainingCategories'
    })
  },
  methods: {
    resetFilters() {
      this.typeId = null;
      this.categoryGroupId = null;
      this.categoryId = null;
      this.isFilterModalVisible = false;
    },
    toggleTypeId(typeId) {
      this.typeId = this.typeId === typeId ? null : typeId;
    },
    toggleCategoryGroupId(groupId) {
      this.categoryGroupId = this.categoryGroupId === groupId ? null : groupId;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/viriables";

.issues-filter {
  &__wrapper {
    position: relative;
  }

  &__buttons-wrapper {
    display: flex;
    gap: 12px;
  }

  &__add-btn {
    min-width: 185px;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }
}

.filter__modal-buttons {
  grid-template-columns: 1fr;
}

@media (max-width: $mobile-big) {
  .tasks-filter__button {
    padding: 12px;
  }

  .issues-filter__add-btn {
    min-width: 0;
    padding: 12px;

    & span {
      display: none;
    }
  }
}
</style>
