import Exceljs from "exceljs";
import {saveAs} from 'file-saver'

export const getExcel = async (
    rows,
    isAutoResize = false,
    workBookName = 'Отчет',
    worksheetName = 'Отчет'
) => {
    const workBook = new Exceljs.Workbook;
    const worksheet = workBook.addWorksheet(worksheetName);

    for (let i = 0; i < rows.length; i++) {
        worksheet.addRow(rows[i]);
    }

    if (isAutoResize) {
        worksheet.columns.forEach(function (column) {
            let maxLength = 0;
            column["eachCell"]({includeEmpty: true}, function (cell) {
                let columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 4 ? 4 : maxLength;
        });
    }

    workBook.xlsx.writeBuffer().then(function (data) {
        let blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
        saveAs(blob, `${workBookName}.xlsx`)
    })
}
