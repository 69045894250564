<template>
  <div>
    <base-functional-line @search-change="event => $store.commit('complain/SET_QUERY', event.target.value)">
      <template>
        <button
          @click="openComplaintPopup()"
          class="button button--mode-primary">
          <icon-plus/>
          Добавить жалобу
        </button>
      </template>
    </base-functional-line>

    <base-content-loaders v-if="loading"/>

    <div
      v-if="complaintsList.length"
      class="base-table">
      <div class="base-table__container">
        <div class="base-table__header base-table__column--complaint">
          <div class="base-table__header-cell">ID</div>
          <div class="base-table__header-cell">Наименование</div>
          <div class="base-table__header-cell"/>
        </div>

        <div v-for="complaint in complaintsList"
             :key="complaint.id"
             class="base-table__row base-table__column--complaint">
          <div class="base-table__column">
            <div class="base-table__text">{{ complaint.id }}</div>
          </div>
          <div class="base-table__column">
            <div class="base-table__text">{{ complaint.name }}</div>
          </div>
          <div class="base-table__column base-table__column--content-right">
            <div class="base-table__short-info">
              <div @click="openComplaintPopup(complaint)">
                <icon-pencil class="base-table__short-info--p-2"/>
              </div>
              <div @click="deleteComplaint(complaint.id)">
                <icon-trash/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <base-no-data v-else-if="!loading"/>
  </div>
</template>

<script>
import IconPlus from "@/components/Icons/IconPlus.vue";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import {modalAdaptiveWidth} from "@/utils/helpers";
import ComplaintsForm from "@/components/TechnicalInspection/NetworkObjectTypes/ComplaintsForm.vue";

export default {
  name: "ComplaintsList",
  components: {IconTrash, IconPencil, BaseNoData, BaseContentLoaders, BaseFunctionalLine, IconPlus},
  props: {
    complaintsList: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      popupName: 'complaint-popup'
    }
  },
  methods: {
    openComplaintPopup(complaint = {}) {
      this.$modal.show(
        ComplaintsForm,
        {
          'popupName': this.popupName,
          'complaint': complaint,
          'createComplaint': this.createComplaint,
          'updateComplaint': this.updateComplaint
        },
        {
          name: this.popupName,
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    createComplaint(payload) {
      this.$store.dispatch('complaints/createComplaint', payload)
        .then(() => {
          this.$store.dispatch('complaints/getComplaintsList', this.$route.params.id);
          this.$modal.hide(this.popupName);
          showToast('Жалоба добавлена', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    updateComplaint(id, payload) {
      this.$store.dispatch('complaints/updateComplaint', {id, payload})
        .then(() => {
          this.$store.dispatch('complaints/getComplaintsList', this.$route.params.id);
          this.$modal.hide(this.popupName);
          showToast('Жалоба изменена', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    deleteComplaint(id) {
      showConfirmationModal('Вы уверены, что хотите удалить жалобу? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('complaints/deleteComplaint', id)
              .then(() => {
                this.$store.dispatch('complaints/getComplaintsList', this.$route.params.id);
                showToast('Жалоба удалена', 'success');
              })
              .catch(error => showAlert('Ошибка', error));
          }
        })
    }
  }
}
</script>

<style scoped>
.base-table__column--complaint {
  grid-template-columns: 60px 1fr 100px;
}
</style>