export const namespaced = true;

export const state = {
    isModalVisible: false,
    isModalHorizontal: false,
    componentPropsData: {},
    modalContentType: '',
    modalHistory: []
}

export const mutations = {
    SET_MODAL_VISIBLE(state, visible) {
        state.isModalVisible = visible;
    },
    TOGGLE_MODAL_HORIZONTAL(state) {
        state.isModalHorizontal = !state.isModalHorizontal;
    },
    SET_COMPONENT_PROPS_DATA(state, propsData) {
        state.componentPropsData = propsData;
    },
    SET_MODAL_CONTENT_TYPE(state, type) {
        state.modalContentType = type;
    },
    ADD_MODAL_HISTORY_ITEM(state, entityId) {
        const historyItem = {
            entityId,
            modalContentType: state.modalContentType,
            propsData: state.componentPropsData
        }
        state.modalHistory.push(historyItem);
    },
    REMOVE_MODAL_HISTORY_LAST_ITEM(state) {
        state.modalHistory.pop();
    },
    CLEAR_MODAL_HISTORY(state) {
        state.modalHistory = [];
    }
}

export const actions = {
    openModal({commit, dispatch}, {modalContentType, entityId, propsData = {}}) {
        commit("SET_MODAL_CONTENT_TYPE", modalContentType);
        commit("SET_COMPONENT_PROPS_DATA", propsData);
        commit("ADD_MODAL_HISTORY_ITEM", entityId);

        dispatchAction(dispatch, modalContentType, entityId);
        commit("SET_MODAL_VISIBLE", true);
    },
    goBack({commit, state, dispatch}) {
        commit("REMOVE_MODAL_HISTORY_LAST_ITEM");

        const previousItem = state.modalHistory[state.modalHistory.length - 1];
        if (previousItem) {
            commit("SET_COMPONENT_PROPS_DATA", previousItem.propsData);
            dispatchAction(dispatch, previousItem.modalContentType, previousItem.entityId);
            commit("SET_MODAL_CONTENT_TYPE", previousItem.modalContentType);
        } else dispatch('closeModal')
    },
    closeModal({commit}) {
        commit("SET_MODAL_VISIBLE", false);
        commit("SET_MODAL_CONTENT_TYPE", '');
        commit("SET_COMPONENT_PROPS_DATA", {});
        commit("CLEAR_MODAL_HISTORY");
    }
}

export const getters = {
    doseTheHistoryHavePreviousItem: state => {
        return state.modalHistory.length > 1
    },
    getPropData: state => key => {
        if (Object.keys(state.componentPropsData).length) {
            return state.componentPropsData[key];
        } else return null
    }
}

function dispatchAction(dispatch, modalContentType, entityId) {
    switch (modalContentType) {
        case 'appeal':
            return dispatch('appealModal/getAppealInfo', entityId, {root: true});
        case 'issue':
            return dispatch('issueModal/getIssueInfo', entityId, {root: true});
        case 'task':
            return dispatch('taskModal/getTaskInfo', entityId, {root: true});
        case 'work':
            return dispatch('works/getWorkInfo', entityId, {root: true});
        case 'department':
            return dispatch('departments/getDepartmentInfo', entityId, {root: true});
        case 'transport':
            return dispatch('autos/getAuto', entityId, {root: true});
        case 'layer':
            return dispatch('layer/getLayer', entityId, {root: true})
        case 'energyConsumptionObject':
            return dispatch('energyConsumptionObjects/getEnergyConsumptionObjectWithMeters', entityId, {root: true})
        case 'networkObject':
            return dispatch('networkObjectPage/getNetworkObjectWithDevicesAndEquipments', entityId, {root: true})
        case 'role':
            return dispatch('role/getRole', entityId, {root: true})
        case 'user':
            return dispatch('user/getUserWithSubscriptions', entityId, {root: true})
    }
}
