<template>
  <div class="vue-dadata-wrapper">
    <dadata-suggestions
      class="vue-dadata-input"
      type="ADDRESS"
      @change="setAddress"
      placeholder="Введите адрес"
      v-model="addressStringModel"
    />
  </div>
</template>

<script>
import Vue from "vue";
import DadataSuggestions from "vue-dadata-suggestions";
import {mapGetters} from "vuex";

export default {
  name: "AddressSelector",
  props: {
    addressString: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      address: {}
    }
  },
  computed: {
    ...mapGetters({
      dadataLocation: 'projectConfig/getProjectLocation'
    }),
    addressStringModel: {
      get() {
        return this.addressString;
      },
      set(value) {
        this.$emit('update:address-string', value);
      }
    }
  },
  beforeMount() {
    Vue.use(DadataSuggestions, {
      token: process.env.VUE_APP_DADATA_API_KEY,
      constraints: {
        locations: this.dadataLocation
      }
    });
  },
  methods: {
    setAddress(i) {
      if (i.data) {
        this.address.region = i.data.region;
        this.address.city = i.data.city;
        this.address.settlement = i.data.settlement_with_type;
        this.address.street = i.data.street_with_type;
        this.address.house = i.data.house;
        this.address.block = i.data.block;
        this.address.flat = i.data.flat;
        this.address.lat = parseFloat(i.data.geo_lat);
        this.address.lon = parseFloat(i.data.geo_lon);
        this.address.qc_geo = i.data.qc_geo;

        this.$emit('set-address', this.address);
      }
    }
  }
}
</script>

<style scoped>
.vue-dadata-wrapper {
  width: 100%;
}
 </style>