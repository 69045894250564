<template>
  <div class="page-wrapper">
    <base-panel
      title="Заявки"
      :is-add-button-visible="false"
      :is-weather-visible="true"
      :is-time-visible="true"
    />
    <div class="base-tables-container">
      <div class="base-table__tabs">
        <div class="base-table__tab"
             :class="{'active': activeTab === 0}"
             @click="activeTab = 0">
          <div class="base-table__tab-content">
            Обращения
            <div v-if="appealsByStateId([AppealState.NEW]).length"
                 class="base-table__tab-indicator">
              {{ appealsByStateId([AppealState.NEW]).length }}
            </div>
          </div>
        </div>
        <div class="base-table__tab"
             :class="{'active': activeTab === 1}"
             @click="activeTab = 1">Текущие
        </div>
        <div class="base-table__tab"
             :class="{'active': activeTab === 2}"
             @click="activeTab = 2">Календарь
        </div>
        <div class="base-table__tab base-table__tab--float-right"
             :class="{'active': activeTab === 3}"
             @click="activeTab = 3">
          Архив
        </div>
      </div>

      <div v-if="activeTab === 0"
           class="appeals-tab">
        <base-functional-line @search-change="searchAppealsByAddress">
          <appeals-list-filter/>
        </base-functional-line>

        <div class="appeal-header">
          <div class="appeal-header__name">Нераспределенные</div>
          <div class="appeal-header__line"/>
        </div>

        <appeals-list
          v-if="appealsByStateId([AppealState.NEW]).length"
          appeal-state="new"
          :appeals-list="appealsByStateId([AppealState.NEW])"/>

        <base-content-loaders
          v-else-if="!appealsByStateId([AppealState.NEW]).length && appealsLoading"/>

        <div v-else
             class="no-result">
          <img src="@/assets/img/no-result.png"
               alt="Нет данных"
               class="no-result__img">
          <div class="no-result__text">
            Нет нераспределенных обращений
          </div>
        </div>

        <div class="appeal-header">
          <div class="appeal-header__name">Обработанные обращения</div>
          <div class="appeal-header__line"/>
        </div>

        <appeals-list
          v-if="appealsByStateId([AppealState.IN_WORK, AppealState.LINKED]).length"
          :appeals-list="appealsByStateId([AppealState.IN_WORK, AppealState.LINKED])"/>

        <base-content-loaders
          v-else-if="!appealsByStateId([AppealState.IN_WORK, AppealState.LINKED]).length && appealsLoading"/>

        <div v-else
             class="no-result">
          <img src="@/assets/img/no-result.png"
               alt="Нет данных"
               class="no-result__img">
          <div class="no-result__text">
            Нет обработанных обращений
          </div>
        </div>
      </div>

      <div v-else-if="activeTab === 2">
        <issues-calendar
          :issues-list="allIssuesList"
          @update-calendar-period="fetchCalendarIssues"
        />
      </div>

      <div v-else-if="activeTab === 3"
           class="archive-issues-tab">
        <archive-issues-form/>

        <div v-if="!allIssuesList.length && !loading"
             class="no-result">
          <img src="@/assets/img/no-result-2.png"
               alt="не сформирован отчёт">
          <div class="no-result__text">
            Сформируйте запрос и получите список
          </div>
          <div class="no-result__subtext">
            Выберите параметры выше и нажмите на  «Сформировать», чтобы получить список архивных заявок
          </div>
        </div>

        <archive-issues-list
          v-if="allIssuesList.length"
          :issues-list="allIssuesList"/>
      </div>

      <div v-else
           class="issues-tab">
        <base-functional-line @search-change="searchIssuesByAddress">
          <issues-list-filter
            :issues-view="issuesView"
            :is-visible-change-view-buttons="true"
            :is-visible-create-issue-button="true"
            @set-list-view="value => this.issuesView = value"/>
        </base-functional-line>

        <div v-if="issuesView === 'list'">
          <issues-list-view
            v-if="filteredIssuesList.length"
            :issues-list="filteredIssuesList"/>

          <base-content-loaders v-if="contentLoading"/>

          <div class="no-result-message"
               v-if="!filteredIssuesList.length">
            Заявки не найдены
          </div>
        </div>
        <div v-else-if="issuesView === 'list-group'">
          <div class="base-table base-table--issues">
            <div class="base-table__container">
              <div class="base-table__header base-table__header--issues">
                <div class="base-table__header-cell">
                  ID
                </div>
                <div class="base-table__header-cell">Тип</div>
                <div class="base-table__header-cell">Заплан. дата начала</div>
                <div class="base-table__header-cell">Проблема</div>
                <div class="base-table__header-cell">Адрес</div>
                <div class="base-table__header-cell">Подразделение</div>
                <div class="base-table__header-cell">Статус</div>
                <div class="base-table__header-cell">Задания</div>
                <div class="base-table__header-cell"/>
              </div>

              <div
                v-for="issuesGroup in groupedByAddressIssuesList"
                :key="issuesGroup.name">
                <base-collapse>
                  <template #toggle="{isCollapsed, toggle}">
                    <div
                      @click="toggle()"
                      class="issues-collapse">
                      <div
                        class="issues-collapse__name"
                        v-if="issuesGroup.name">{{ issuesGroup.name }}
                      </div>
                      <div
                        class="issues-collapse__name"
                        v-else>Населенный пункт не указан
                      </div>
                      <div class="issues-collapse__line"/>
                      <icon-arrow-double
                        class="issues-collapse__toggle"
                        :class="{'issues-collapse__toggle--collapsed': isCollapsed}"
                      />
                    </div>
                  </template>

                  <template #body>
                    <div>
                      <div
                        v-for="issuesAddressGroup in issuesGroup.value"
                        :key="issuesAddressGroup.name">

                        <base-collapse>
                          <template #toggle="{isCollapsed, toggle}">
                            <div
                              @click="toggle()"
                              class="issues-collapse issues-collapse--child">
                              <div
                                v-if="issuesAddressGroup.name"
                                class="issues-collapse__name">{{ issuesAddressGroup.name }}
                              </div>
                              <div
                                v-else
                                class="issues-collapse__child-name">Улица не указана
                              </div>
                              <div class="issues-collapse__line"/>
                              <icon-arrow-double
                                class="issues-collapse__toggle"
                                :class="{'issues-collapse__toggle--collapsed': isCollapsed}"
                              />
                            </div>
                          </template>

                          <template #body>
                            <issues-item
                              v-for="issue in issuesAddressGroup.value"
                              :key="issue.id"
                              :issue="issue"/>
                          </template>
                        </base-collapse>
                      </div>
                    </div>
                  </template>
                </base-collapse>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <issues-list-map
            :issues-list="filteredIssuesList"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import IssueState from "@/models/serviceDesk/IssueState";
import {mapGetters, mapState} from "vuex";
import BasePanel from "@/components/BasePanel";
import BaseFunctionalLine from "@/components/BaseFunctionalLine";
import IssuesListFilter from "@/components/ServiceDesk/Issues/IssuesListFilter";
import IssuesListView from "@/components/ServiceDesk/Issues/IssuesListView";
import BaseContentLoaders from "@/components/BaseContentLoaders";
import AppealState from "@/models/callCenter/AppealState";
import AppealsListFilter from "@/components/ServiceDesk/Issues/AppealsListFilter";
import AppealsList from "@/components/ServiceDesk/Issues/AppealsList";
import IssuesListMap from "@/components/ServiceDesk/Issues/IssuesListMap";
import searchAppealsByAddress from "@/mixins/searchAppealsByAddress";
import ArchiveIssuesList from "@/components/ServiceDesk/Issues/ArchiveIssuesList.vue";
import IssuesCalendar from "@/components/ServiceDesk/Issues/IssuesCalendar.vue";
import IssuesItem from "@/components/ServiceDesk/Issues/IssuesItem.vue";
import BaseCollapse from "@/components/BaseCollapse.vue";
import IconArrowDouble from "@/components/Icons/IconArrowDouble.vue";
import ArchiveIssuesForm from "@/components/ServiceDesk/Issues/ArchiveIssuesForm.vue";

export default {
  name: "IssuesListPage",
  components: {
    ArchiveIssuesForm,
    IconArrowDouble,
    BaseCollapse,
    IssuesItem,
    IssuesCalendar,
    ArchiveIssuesList,
    IssuesListMap,
    AppealsList,
    AppealsListFilter,
    BaseContentLoaders,
    IssuesListView,
    IssuesListFilter,
    BaseFunctionalLine,
    BasePanel
  },
  mixins: [searchAppealsByAddress],
  data() {
    return {
      timing: null,
      activeTab: 1,
      isTaskModalActive: false,
      isCreateIssueModalActive: false,
      isAppealModalActive: false,
      issuesView: 'list',
      relatedIssueId: 0,
      relatedAppealId: 0,
      calendarPeriod: null
    }
  },
  computed: {
    ...mapState({
      issues: state => state.issues.issues,
      loading: state => state.issues.loading,
      meta: state => state.issues.meta,
      issuesPage: state => state.issues.page,
      appealsPage: state => state.appeals.page,
      appealsLoading: state => state.appeals.loading,
      isIssueModalVisible: state => state.issue.isIssueModalVisible,
      allIssuesList: state => state.issues.allIssues
    }),
    ...mapGetters({
      appealsByStateId: 'appeals/getAppealListByStateId',
      filteredIssuesList: 'issues/getFilteredIssues',
      groupedByAddressIssuesList: 'issues/getGroupedByAddressIssues'
    }),
    IssueState: () => IssueState,
    AppealState: () => AppealState,
    contentLoading() {
      return !this.filteredIssuesList.length && this.loading
    }
  },
  watch: {
    activeTab: function (tabNumber, oldTabNumber) {
      if (tabNumber !== 2) this.calendarPeriod = null;
      if (tabNumber === 1) this.$store.commit('issues/SET_SEARCH_QUERY', '');
      if (oldTabNumber === 3) this.$store.commit('issues/SET_ALL_ISSUES', []);
    }
  },
  mounted() {
    this.fetchData();
    this.timing = setInterval(this.fetchData, process.env.VUE_APP_SERVICE_DESK_GET_DATA_TIMING);

    this.$store.dispatch('departments/getAllDepartments');
    this.$store.dispatch('task_types/getAllTaskTypes');
    this.$store.dispatch('issueTypes/getIssueTypes');
    this.$store.dispatch('serviceDeskCategories/getAllCategories');
    this.$store.dispatch('serviceDeskCategories/getCategoryGroups');
  },
  beforeDestroy() {
    clearInterval(this.timing);
    this.$store.commit('issues/SET_ISSUES', []);
    this.$store.commit('issues/SET_ALL_ISSUES', []);
    this.$store.commit('issues/SET_FILTER_PARAMS', null);
    this.$store.commit('issues/SET_META', null);
    this.$store.commit('issues/SET_LINKS', null);
    this.$store.commit('issues/SET_PAGE', null);

    this.$store.dispatch('baseSideModal/closeModal');
  },
  methods: {
    fetchData() {
      this.$store.dispatch("issues/getIssues", this.issuesPage);
      this.$store.dispatch("appeals/getAppeals", this.appealsPage);
    },
    fetchCalendarIssues(period) {
      const {period_start, period_end} = period;
      if (
        !this.calendarPeriod ||
        (this.calendarPeriod.period_start !== period_start &&
          this.calendarPeriod.period_end !== period_end)
      ) {
        this.$store.dispatch('issues/getAllIssues', {
          planned_date_start: period_start,
          planned_date_end: period_end
        })
      }
      this.calendarPeriod = period;
    },
    searchIssuesByAddress(event) {
      const searchString = event.target.value;
      this.$store.commit('issues/SET_SEARCH_QUERY', searchString);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.issues-list-header {
  font-size: 22px;
  font-weight: 600;
  color: var(--text-primary);
}

.appeal-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  padding: 0 24px;

  margin-top: 26px;
  margin-bottom: 16px;

  &__name {
    flex: 0 0 auto;
    @include font-style(600, 14px, 16px, var(--text-primary));
  }

  &__line {
    width: 100%;
    height: 1px;
    background-color: var(--border-main);
  }
}

.issues-collapse {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-top: 16px;
  cursor: pointer;

  &--child {
    padding: 12px 0 12px 24px;
  }

  &__name {
    @include font-style(600, 14px, 16px, var(--text-primary));
  }

  &__line {
    height: 1px;
    flex: 1;
    background-color: var(--border-main);
  }

  &__toggle {
    stroke: var(--ico);
    transform: rotateZ(-90deg);

    &--collapsed {
      transform: rotateZ(90deg);
    }
  }
}
</style>
