import TasksService from "@/services/TasksService";
import WorkService from "@/services/WorkService";
import WaterFlowsService from "@/services/WaterFlowsService";
import TransportOrdersService from "@/services/TransportOrdersService";
import NetworkSpreadService from "@/services/NetworkSpreadService";
import HistoryService from "@/services/HistoryService";
import CommentsService from "@/services/CommentsService";
import TaskConsumptionsService from "@/services/TaskConsumptionsService";
import DetectedProblemsService from "@/services/DetectedProblemsService";

export const namespaced = true;

export const state = {
    task: {},
    worksList: [],
    mechanicalEquipmentsList: [],
    waterFlowsList: [],
    transportOrdersList: [],
    networkSpread: {},
    history: [],
    attachmentsList: [],
    commentsList: [],
    consumptionsList: [],
    detectedProblemsList: [],
    loading: false,
    error: null
};

export const mutations = {
    SET_TASK(state, task) {
        state.task = task;
    },
    SET_WORKS_LIST(state, worksList) {
        state.worksList = worksList;
    },
    SET_MECHANICAL_EQUIPMENTS_LIST(state, mechanicalEquipmentsList) {
        state.mechanicalEquipmentsList = mechanicalEquipmentsList;
    },
    DELETE_MECHANICAL_EQUIPMENT(state, equipmentId) {
        const index = state.mechanicalEquipmentsList.findIndex(equipment => equipment.id === equipmentId);
        state.mechanicalEquipmentsList.splice(index, 1);
    },
    SET_WATER_FLOWS_LIST(state, waterFlowsList) {
        state.waterFlowsList = waterFlowsList;
    },
    SET_TRANSPORT_ORDERS_LIST(state, transportOrdersList) {
        state.transportOrdersList = transportOrdersList;
    },
    SET_NETWORK_SPREAD(state, networkSpread) {
        state.networkSpread = networkSpread;
    },
    SET_HISTORY(state, history) {
        state.history = history;
    },
    SET_ATTACHMENTS_LIST(state, attachmentsList) {
        state.attachmentsList = attachmentsList;
    },
    SET_COMMENTS_LIST(state, commentsList) {
        state.commentsList = commentsList;
    },
    SET_CONSUMPTIONS_LIST(state, consumptionsList) {
        state.consumptionsList = consumptionsList;
    },
    SET_DETECTED_PROBLEMS_LIST(state, detectedProblemsList) {
        state.detectedProblemsList = detectedProblemsList;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_ATTACHMENT(state, attachmentId) {
        const index = state.attachmentsList.findIndex(item => item.id === attachmentId);
        state.attachmentsList.splice(index, 1);
    }
}

export const actions = {
    async getTaskInfo({commit, dispatch}, taskId) {
        commit("SET_LOADING", true);

        await Promise.all([
            dispatch('getTask', taskId),
            dispatch('getWorksList', taskId),
            dispatch('getMechanicalEquipmentsList', taskId),
            dispatch('getWaterFlowsList', taskId),
            dispatch('getTransportOrdersList', taskId),
            dispatch('getNetworkSpread', taskId),
            dispatch('getHistory', taskId),
            dispatch('getAttachmentsList', taskId),
            dispatch('getCommentsList', taskId),
            dispatch('getConsumptionsList', taskId),
            dispatch('getDetectedProblemsList', taskId)
        ])
            .finally(() => commit("SET_LOADING", false))
    },
    getTask({commit}, taskId) {
        return new Promise((resolve, reject) => {
            TasksService.getTask(taskId)
                .then(task => {
                    commit("SET_TASK", task);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
        })
    },
    getWorksList({commit}, taskId) {
        return new Promise((resolve, reject) => {
            WorkService.getTaskWorks(taskId)
                .then(worksList => {
                    commit("SET_WORKS_LIST", worksList);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                });
        })
    },
    getMechanicalEquipmentsList({commit}, taskId) {
        return new Promise((resolve, reject) => {
            TasksService.fetchTaskMechanicalEquipments(taskId)
                .then(mechanicalEquipmentsList => {
                    commit("SET_MECHANICAL_EQUIPMENTS_LIST", mechanicalEquipmentsList);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                });
        })
    },
    getWaterFlowsList({commit}, taskId) {
        return new Promise((resolve, reject) => {
            WaterFlowsService.fetchTaskWaterFlowsList(taskId)
                .then(waterFlowsList => {
                    commit("SET_WATER_FLOWS_LIST", waterFlowsList);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                });
        })
    },
    getTransportOrdersList({commit}, taskId) {
        const filterParams = {
            task_id: taskId
        };

        return new Promise((resolve, reject) => {TransportOrdersService.fetchTransportOrders(filterParams)
            .then(transportOrdersList => {
                commit("SET_TRANSPORT_ORDERS_LIST", transportOrdersList);
                resolve();
            })
            .catch(error => {
                commit("SET_ERROR", error);
                reject();
            });
        })
    },
    getNetworkSpread({commit}, taskId) {
        return new Promise((resolve, reject) => {
            NetworkSpreadService.fetchNetworkSpread(taskId)
                .then(networkSpread => {
                    commit("SET_NETWORK_SPREAD", networkSpread);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                });
        })
    },
    getHistory({commit}, taskId) {
        return new Promise((resolve, reject) => {
            HistoryService.getTaskHistory(taskId)
                .then(history => {
                    commit("SET_HISTORY", history);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                });
        })
    },
    getAttachmentsList({commit}, taskId) {
        return new Promise((resolve, reject) => {
            TasksService.getTaskFiles(taskId)
                .then(attachmentsList => {
                    commit("SET_ATTACHMENTS_LIST", attachmentsList);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                });
        })
    },
    getCommentsList({commit}, taskId) {
        return new Promise((resolve, reject) => {
            CommentsService.getTaskComments(taskId)
                .then(commentsList => {
                    commit("SET_COMMENTS_LIST", commentsList);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                });
        })
    },
    getConsumptionsList({commit}, taskId) {
        return new Promise((resolve, reject) => {
            TaskConsumptionsService.fetchTaskConsumptionsList(taskId)
                .then(consumptionsList => {
                    commit("SET_CONSUMPTIONS_LIST", consumptionsList);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error)
                    reject();
                })
        })
    },
    getDetectedProblemsList({commit}, taskId) {
        return new Promise((resolve, reject) => {
            DetectedProblemsService.fetchDetectedProblemsList(taskId)
                .then(detectedProblemsList => {
                    commit("SET_DETECTED_PROBLEMS_LIST", detectedProblemsList);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
        })
    }
}

export const getters = {
    getHistoryFirstItem: state => {
        return state.history[0];
    }
}
