import IssueService from "@/services/IssueService";
import {groupBy} from "@/utils/helpers";

export const namespaced = true

export const state = {
    issues: [],
    allIssues: [],
    relatedIssuesList: [],
    filterParams: null,
    page: null,
    meta: null,
    links: null,
    loading: false,
    error: null,
    typeId: null,
    categoryGroupId: null,
    categoryId: null,
    searchQuery: ''
}

export const mutations = {
    SET_ISSUES(state, issues) {
        state.issues = issues
    },
    SET_ALL_ISSUES(state, issues) {
        state.allIssues = issues;
    },
    SET_RELATED_ISSUES_LIST(state, issues) {
        state.relatedIssuesList = issues;
    },
    SET_FILTER_PARAMS(state, params) {
        state.filterParams = params;
    },
    SET_FILTER_PARAM(state, {name, value}) {
        state.filterParams[name] = value;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LINKS(state, links) {
        state.links = links
    },
    SET_LOADING(state, loading) {
        state.loading = loading
    },
    SET_ERROR(state, error) {
        state.error = error
    },
    SET_TYPE_ID(state, id) {
        state.typeId = id;
    },
    SET_CATEGORY_GROUP_ID(state, id) {
        state.categoryGroupId = id;
    },
    SET_CATEGORY_ID(state, id) {
        state.categoryId = id;
    },
    SET_SEARCH_QUERY(state, query) {
        state.searchQuery = query;
    }
}

export const actions = {
    getIssues({commit, state}) {
        commit("SET_LOADING", true);
        IssueService.fetchIssuesListItems(state.filterParams, state.page)
            .then(({issues, meta, links}) => {
                commit("SET_ISSUES", issues);
                commit("SET_META", meta);
                commit("SET_LINKS", links);
            })
            .catch(error => {
                commit("SET_ERROR", error);
            })
            .finally(() => commit("SET_LOADING", false));
    },
    getAllIssues({commit}, params) {
        IssueService.getAllIssues(params)
            .then(issues => commit("SET_ALL_ISSUES", issues))
            .catch(error => commit("SET_ERROR", error));
    },
    getIssuesForLink({commit}, params) {
        IssueService.getAllIssues(params)
            .then(issues => commit("SET_RELATED_ISSUES_LIST", issues))
            .catch(error => commit("SET_ERROR", error))
    },
    createIssue({commit}, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.createIssue(payload)
                .then(issueId => resolve(issueId))
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
                .finally(() => commit("SET_LOADING", false));
        })
    }
}

export const getters = {
    getIssues: state => {
        return state.issues;
    },
    getAllIssues: state => {
        return state.allIssues;
    },
    getIssue: state => id => {
        return state.issues.find(issue => issue.id === id);
    },
    filterIssuesByType: state => issues => {
        return state.typeId ? issues.filter(issue => issue.type_id === state.typeId) : issues;
    },
    filterIssuesByCategoryGroup: state => issues => {
        return state.categoryGroupId ?
            issues.filter(issue => issue.category.group_id === state.categoryGroupId) :
            issues
    },
    filterIssuesByCategory: state => issues => {
        return state.categoryId ? issues.filter(issue => issue.category.id === state.categoryId) : issues;
    },
    searchIssuesByAddress: state => issues => {
        return state.searchQuery.length ?
            issues.filter(issue => {
                const addressString = issue.getFullAddress(' ');
                return addressString.toLowerCase().includes(state.searchQuery.toLowerCase())
            }) :
            issues
    },
    filterIssues: (state, getters) => issues => {
        return getters.searchIssuesByAddress(
            getters.filterIssuesByCategoryGroup(
                getters.filterIssuesByCategory(
                    getters.filterIssuesByType(issues)
                )
            )
        )
    },
    getFilteredIssues: (state, getters) => {
        return getters.filterIssues(state.issues);
    },
    getFilteredArchiveIssues: (state, getters) => {
        return getters.filterIssues(state.allIssues);
    },
    getGroupedByAddressIssues: state => {
        const addressKeyGetter = issue => issue.address.settlement ? issue.address.settlement : issue.address.city;
        const issuesGroupedByAddress = groupBy(state.issues, addressKeyGetter);

        let issuesGroupedByAddressAndStreet = [];
        issuesGroupedByAddress.forEach((issuesGroup, key) => {
            const group = groupBy(issuesGroup, issue => issue.address.street);
            issuesGroupedByAddressAndStreet.push({
                name: key,
                value: Array.from(group, ([name, value]) => ({name, value}))
            });
        });

        return issuesGroupedByAddressAndStreet.length ?
            issuesGroupedByAddressAndStreet.sort((a, b) => {
                return a.name ? a.name.localeCompare(b.name) : false
            }) : []
    }
}
