import UserService from "@/services/UserService";
import {getError} from "@/utils/helpers";
import SubscriptionsService from "@/services/SubscriptionsService";

export const namespaced = true;

export const state = {
    users: [],
    fullUsersList: [],
    user: null,
    userSubscriptions: [],
    permissions: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    structuralUnitUsersList: [],
    page: 1,
    userLoading: false,
    searchQuery: ''
};

export const mutations = {
    SET_USERS(state, users) {
        state.users = users;
    },
    SET_FULL_USERS_LIST(state, fullUsersList) {
        state.fullUsersList = fullUsersList;
    },
    SET_USER(state, user) {
        state.user = user;
    },
    SET_PERMISSIONS(state, permissions) {
        state.permissions = permissions;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_STRUCTURAL_UNIT_USERS_LIST(state, users) {
        state.structuralUnitUsersList = users;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_USER_SUBSCRIPTIONS(state, subscriptions) {
        state.userSubscriptions = subscriptions;
    },
    SET_USER_LOADING(state, loading) {
        state.userLoading = loading;
    },
    SET_SEARCH_QUERY(state, query) {
        state.searchQuery = query;
    }
};

export const actions = {
    getUsers({ commit, state }) {
        commit("SET_LOADING", true);
        UserService.getUsers(state.page, state.searchQuery)
            .then(({users, meta, links}) => {
                commit("SET_USERS", users);
                commit("SET_META", meta);
                commit("SET_LINKS", links);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    async getUserWithSubscriptions({commit, dispatch}, userId) {
        commit("SET_USER_LOADING", true);
        await Promise.all([
            dispatch('getUser', userId),
            dispatch('getUserSubscriptions', userId)
        ])
            .finally(() => commit("SET_USER_LOADING", false))
    },
    getUser({commit}, user_id) {
        return new Promise((resolve, reject) => {
            UserService.getUser(user_id)
                .then(user => {
                    resolve(user);
                    commit("SET_USER", user);
                })
                .catch(error => {
                    const processedError = getError(error);
                    reject(processedError);
                    commit("SET_ERROR", processedError);
                })
        })
    },
    getUserSubscriptions({commit}, userId) {
        commit("SET_LOADING", true);
        SubscriptionsService.fetchUserSubscriptions(userId)
            .then(userSubscriptions => commit("SET_USER_SUBSCRIPTIONS", userSubscriptions))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    getAllUsers({ commit }) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            UserService.getAllUsers()
                .then(users => {
                    commit("SET_FULL_USERS_LIST", users);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
                .finally(() => commit("SET_LOADING", false));
        })
    },
    getStructuralUniUsersList({commit}) {
        UserService.fetchStructuralUnitUsers()
            .then(users => commit("SET_STRUCTURAL_UNIT_USERS_LIST", users))
            .catch(error => commit("SET_ERROR", error))
    },
    getPermissions({commit}) {
        UserService.getPermissions()
            .then(permissions => commit("SET_PERMISSIONS", permissions))
            .catch(error => commit("SET_ERROR", error));
    },
    createUser({commit}, payload) {
        return new Promise((resolve, reject) => {
            UserService.createUser(payload)
                .then(() => resolve())
                .catch(error => {
                    const processedError = getError(error);
                    commit("SET_ERROR", processedError);
                    reject(processedError);
                })
        })
    },
    updateUser({commit}, {userId, payload}) {
        return new Promise((resolve, reject) => {
            UserService.updateUser(userId, payload)
                .then(() => resolve())
                .catch(error => {
                    const processedError = getError(error);
                    commit("SET_ERROR", processedError);
                    reject(processedError);
                })
        })
    },
    changeUserPassword(context, {userId, payload}) {
        return new Promise((resolve, reject) => {{
            UserService.changeUserPassword(userId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        }})
    },
    assignRole({commit}, {user_id, role_id}) {
        return new Promise((resolve, reject) => {
            UserService.assignRole(user_id, role_id)
                .then(() => resolve())
                .catch(error => {
                    const processedError = getError(error);
                    commit("SET_ERROR", processedError);
                    reject(processedError);
                })
        })
    },
    removeRole({commit}, {user_id, role_id}) {
        return new Promise((resolve, reject) => {
            UserService.removeRole(user_id, role_id)
                .then(() => resolve())
                .catch(error => {
                    const processedError = getError(error);
                    commit("SET_ERROR", processedError);
                    reject(processedError);
                })
        })
    },
    blockUser(context, userId) {
        return new Promise((resolve, reject) => {
            UserService.blockUser(userId)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    unblockUser(context, userId) {
        return new Promise((resolve, reject) => {
            UserService.unblockUser(userId)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
};

export const getters = {
    getUser: state => id => {
        return state.users.find(user => user.id === Number.parseInt(id));
    },
    getUserName: state => id => {
        const user = state.users.find(user => user.id === Number.parseInt(id))
        if (user) return  user.attributes.name;
    },
    users: state => {
        return state.users;
    },
    meta: state => {
        return state.meta;
    },
    links: state => {
        return state.links;
    },
    loading: state => {
        return state.loading;
    },
    error: state => {
        return state.error;
    }
};