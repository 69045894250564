import * as API from './API';
import Resource from "@/models/serviceDesk/Resource";
import {getError} from "@/utils/helpers";

export default {
    fetchResources(page) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/resources?page=${page}`)
                .then(response => {
                    resolve({
                        resourcesList: response.data.data.map(resource => new Resource({
                            id: resource.id,
                            ...resource.attributes
                        })),
                        meta: response.data.meta
                    });
                })
                .catch(error => reject(getError(error)));
        })
    },
    fetchAllResources() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/resources/all`)
                .then(response => resolve(response.data.data
                    .map(resource => new Resource({id: resource.id, ...resource.attributes}))))
                .catch(error => reject(getError(error)));
        })
    },
    fetchAvailableResources(filterParams) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/resources/available`, {params: filterParams})
                .then(response => resolve(response.data.data
                    .map(resource => new Resource({id: resource.id, ...resource.attributes}))))
                .catch(error => reject(getError(error)));
        })
    },
    createResource(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/resources`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    updateResource(resourceId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`service-desk/resources/${resourceId}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    deleteResource(resourceId) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`service-desk/resources/${resourceId}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    }
}