<template>
  <div class="billing-form-wrapper">

    <div class="form-item">
      <div class="form-item__name">По адресу</div>
      <div class="form-item__value">
        <div class="vue-dadata-wrapper vue-dadata-wrapper--w-100">
          <dadata-suggestions
            type="ADDRESS"
            class="vue-dadata-input"
            @change="setAddress"
            v-model="full_address"
            placeholder="Начните вводить адрес"
          />
        </div>
      </div>
    </div>

    <div class="form-item">
      <div class="form-item__name">По ФИО абонента</div>
      <div class="form-item__value">
        <input
          v-model="filter.client_name"
          class="base-input base-input--bordered"
          placeholder="Введите ФИО абонента"
          type="text"
        >
      </div>
    </div>

    <div class="form-item-group">
      <div class="form-item">
        <div class="form-item__name">По номеру договора</div>
        <div class="form-item__value">
          <input
            v-model="filter.contract_number"
            class="base-input base-input--bordered"
            placeholder="Введите номер договора"
            type="text"
          >
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">По дате заключения</div>
        <div class="form-item__value">
          <date-picker
            id="connectionDate"
            class="base-date-picker"
            placeholder="Выберите период"
            v-model="filter.open_date"
            :default-value="defaultValue"
            range
            format="DD.MM.YYYY"
            type="date"
            value-type="YYYY-MM-DD"
            @clear="filter.open_date = null"
          />
        </div>
      </div>
    </div>

    <div class="form-item-group">
      <div class="form-item">
        <div class="form-item__name">По типу клиента</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            v-model="filter.contract_type"
            :options="contractTypesList"
            placeholder="Выберите из списка"
          />
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">По статусу договора</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            v-model="filter.status"
            :options="contractStatusesList"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
    </div>

    <div class="form-item-group">
      <div class="form-item">
        <div class="form-item__name">По структуре потреб.</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            v-model="filter.structure"
            :options="structuresList"
            placeholder="Выберите из списка"
          />
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">По статусу ПУ</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            v-model="filter.meter_status"
            :options="meterStatusesList"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
    </div>

    <div class="form-item-group">
      <div class="form-item">
        <div class="form-item__name">По зоне водоснабжения</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            v-model="filter.zone_ws_id"
            :options="$store.getters['zone/getZonesByNetworkType'](1)"
            placeholder="Выберите из списка"
            :reduce="zone => zone.id"
            :get-option-label="(option) => option.attributes.name"
          />
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">По зоне водоотведения</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            v-model="filter.zone_wd_id"
            :options="$store.getters['zone/getZonesByNetworkType'](2)"
            placeholder="Выберите из списка"
            :reduce="zone => zone.id"
            :get-option-label="(option) => option.attributes.name"
          />
        </div>
      </div>
    </div>

    <div class="form-item">
      <div class="form-item">
        <div class="form-item__name">По объёму потребления ресурса</div>
        <div class="form-item__value">
          <date-picker
            id="consumptionPeriod"
            v-model="filter.consumption_period"
            :default-value="defaultValue"
            class="base-date-picker"
            format="MMMM YYYY г."
            placeholder="Выберите период"
            range
            type="month"
            value-type="YYYY-MM-DD"
            @clear="filter.consumption_period = null"
          />
        </div>
      </div>

      <div class="form-item-group">
        <div class="form-item">
          <div class="form-item__value">
            <input
              id="consValue-min"
              v-model="filter.consumption_values[0]"
              class="base-input base-input--bordered"
              placeholder="Значение от"
              type="text"
              @change="consValueMinFix($event)"
            >
          </div>
        </div>

        <div class="form-item">
          <div class="form-item__value">
            <input
              id="consValue-max"
              v-model="filter.consumption_values[1]"
              class="base-input base-input--bordered"
              placeholder="Значение до"
              type="text"
              @change="consValueMaxFix($event)"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="form-item-group">
      <div class="form-item">
        <div class="form-item__name">По объёму задолженности</div>
        <div class="form-item__value">
          <input
            id="debt-min"
            class="base-input base-input--bordered"
            placeholder="Минимальное значение"
            type="text"
            @change="debtMinFix($event)"
          >
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__value">
          <input
            id="debt-max"
            class="base-input base-input--bordered"
            placeholder="Максимальное значение"
            type="text"
            @change="debtMaxFix($event)"
          >
        </div>
      </div>
    </div>

    <div class="billing-form-wrapper__buttons">
      <button
        class="button button--mode-primary"
        @click="submitFilter()"
      >
        Применить
        <loader v-if="loader" />
      </button>

      <button
        class="button button--mode-outline"
        @click="clearFilter()"
      >
        <icon-refresh />
        Сбросить
      </button>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Loader from "@/layout/BaseLoader.vue";
import Vue from "vue";
import DadataSuggestions from "vue-dadata-suggestions";
import {mapGetters} from "vuex";
import IconRefresh from "@/components/Icons/IconRefresh.vue";
import {showAlert} from "@/utils/notification";

export default {
  name: "BillingClientsFilter",
  components: {IconRefresh, DatePicker, Loader},
  data() {
    return {
      full_address: '',
      filter: {
        region: '',
        city: '',
        settlement: '',
        street: '',
        house: '',
        block: '',
        zone_ws_id: null,
        zone_wd_id: null,
        contract_number: '',
        client_name: '',
        status: '',
        open_date: [],
        meter_status: '',
        saldo: [],
        consumption_period: null,
        consumption_values: [],
        structure: '',
        contract_type: ''
      },
      defaultValue: new Date(),
      loader: false,
      contractTypesList: [
        {id: 1, label: 'Физические лица'},
        {id: 2, label: 'Юридические лица'}
      ],
      contractStatusesList: [
        {id: 1, label: 'Открыт'},
        {id: 2, label: 'Закрыт'}
      ],
      structuresList: [
        {id: 1, label: 'Холодная вода'},
        {id: 2, label: 'Стоки холодной воды'},
        {id: 3, label: 'Стоки горячей воды'},
        {id: 4, label: 'Неизвестно'}
      ],
      meterStatusesList: [
        {id: 1, label: 'Активен'},
        {id: 2, label: 'В ремонте'},
        {id: 3, label: 'На поверке'},
        {id: 4, label: 'Отключен/закрыт'}
      ]
    }
  },
  computed: {
    ...mapGetters({
      dadataLocation: 'projectConfig/getProjectLocation'
    }),
    validateForm: function () {
      return () => {
        let payload = JSON.parse(JSON.stringify(this.filter));
        if (payload.consumption_values[0] == null && payload.consumption_values[1] != null) {
          showAlert('Ошибка', 'Заполните поле "Значение от', 'error');
          return false;
        }
        if (payload.consumption_values[0] != null && payload.consumption_values[1] == null) {
          showAlert('Ошибка', 'Заполните поле "Значение до', 'error');
          return false;
        }
        if (payload.consumption_period == null && payload.consumption_values[0] != null) {
          showAlert('Ошибка', 'Выберите период объема потребления ресурса', 'error');
          return false;
        }
        if (payload.consumption_period != null && payload.consumption_values[0] == null) {
          showAlert('Ошибка', 'Заполните поля "Значение от" и "Значение до', 'error');
          return false;
        }
        if (payload.saldo[0] == null && payload.saldo[1] != null) {
          showAlert('Ошибка', 'Заполните поле "Минимальное значение', 'error');
          return false;
        }
        if (payload.saldo[1] == null && payload.saldo[0] != null) {
          showAlert('Ошибка', 'Заполните поле "Максимальное значение', 'error');
          return false;
        }
      }
    },
    cleanFilter: function () {
      return filter => {
        let payload = JSON.parse(JSON.stringify(filter))
        for (let propName in payload) {
          if (payload[propName] === null
              || payload[propName] === 0
              || payload[propName] === undefined
              || payload[propName] === ""
              || payload[propName].length === 0) {
            delete payload[propName]
          }
        }
        return payload
      }
    }
  },
  mounted() {
    Vue.use(DadataSuggestions, {
      token: process.env.VUE_APP_DADATA_API_KEY,
      constraints: {
        locations: this.dadataLocation
      }
    });

    this.$store.dispatch("zone/getAllZones");
  },
  methods: {
    submitFilter() {
      if (this.validateForm() === false) return false;

      this.$store.commit('client/SET_FILTER_PARAMS', this.cleanFilter(this.filter))
      this.loader = true;
      this.$store.dispatch("client/getClients")
          .then(() => {
            this.loader = false;
            this.$store.commit("client/SET_TAB_INDEX", 1);
            this.$emit("data-complete", this.cleanFilter(this.filter));
          })
    },
    clearFilter() {
      this.full_address = '';
      this.filter.region = '';
      this.filter.city = '';
      this.filter.settlement = '';
      this.filter.street = '';
      this.filter.house = '';
      this.filter.block = '';
      this.filter.zone_ws_id = null;
      this.filter.zone_wd_id = null;
      this.filter.contract_number = '';
      this.filter.client_name = '';
      this.filter.status = '';
      this.filter.open_date = [];
      this.filter.meter_status = '';
      this.filter.saldo = [];
      this.filter.consumption_period = null;
      this.filter.consumption_values = [];
      this.filter.structure = '';
      this.filter.contract_type = '';

      document.getElementById('debt-min').value = '';
      document.getElementById('debt-max').value = '';
    },
    debtMinFix(event) {
      if (event.target.value == '') {
        delete this.filter.saldo[1]
      } else {
        this.filter.saldo[1] = -event.target.value
      }
      if (this.filter.saldo[0] == null && this.filter.saldo[1] == null) {
        this.filter.saldo = []
      }
    },
    debtMaxFix(event) {
      if (event.target.value == '') {
        delete this.filter.saldo[0]
      } else {
        this.filter.saldo[0] = -event.target.value
      }
      if (this.filter.saldo[0] == null && this.filter.saldo[1] == null) {
        this.filter.saldo = []
      }

    },
    consValueMinFix(event) {
      if (event.target.value == '') {
        delete this.filter.consumption_values[0]
      }
      if (this.filter.consumption_values[0] == null && this.filter.consumption_values[1] == null) {
        this.filter.consumption_values = []
      }
    },
    consValueMaxFix(event) {
      if (event.target.value == '') {
        delete this.filter.consumption_values[1]
      }
      if (this.filter.consumption_values[0] == null && this.filter.consumption_values[1] == null) {
        this.filter.consumption_values = []
      }
    },
    setAddress(event) {
      if (typeof event === 'object') {
        this.filter.region = event.data.region;
        this.filter.city = event.data.city;
        this.filter.settlement = event.data.settlement;
        this.filter.street = event.data.street;
        this.filter.house = event.data.house;
        this.filter.block = event.data.block;
      }
    }
  }
}
</script>

<style scoped>
</style>
