import * as API from "@/services/API";
import User from "@/models/User";
import {getError} from "@/utils/helpers";
import Permission from "@/models/Permission";

export default {
    getAllUsers() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/users/all`)
                .then(response => resolve(response.data.data.map(item => new User(item))))
                .catch(error => reject(getError(error)));
        })
    },
    getUser(userId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/users/${userId}`)
                .then(response => resolve(new User(response.data.data)))
                .catch(error => reject(getError(error)));
        })
    },
    getUsers(page, query) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/users?page=${page}`, {params: {term: query}})
                .then(response => resolve({
                    users: response.data.data.map(item => new User(item)),
                    meta: response.data.meta,
                    links: response.data.links
                }))
                .catch(error => reject(getError(error)));
        })
    },
    createUser(data){
        return API.apiClient.post(`/users`,data);
    },
    updateUser(user_id, data){
        return API.apiClient.patch(`/users/${user_id}`,data);
    },
    changeUserPassword(userId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/users/${userId}/change-password`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    getPermissions() {
        return new Promise((resolve, reject) => {
            API.apiClient.get('/permissions')
                .then(response => resolve(response.data.data.map(item => new Permission(item))))
                .catch(error => reject(getError(error)));
        })
    },
    assignRole(user_id, role_id) {
        return API.apiClient.post(`/users/${user_id}/roles/${role_id}`);
    },
    removeRole(user_id, role_id) {
        return API.apiClient.delete(`/users/${user_id}/roles/${role_id}`);
    },
    fetchStructuralUnitUsers() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/structural-units/employees`)
                .then(response => resolve(response.data.data.map(item => new User(item))))
                .catch(error => reject(getError(error)))
        })
    },
    blockUser(userId) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/users/${userId}/block`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    unblockUser(userId) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/users/${userId}/unblock`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    }
};