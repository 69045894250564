<template>
  <base-map
    :base-layers="baseLayers"
    :layers="layers"
    :zoom="zoom"
    :center="center"
    :markers="createMarkers"
  />
</template>

<script>
import BaseMap from "@/components/BaseMap.vue";
import {mapState} from "vuex";
import Vue from 'vue';
import PersonalListNetworkObjectMapMarker
  from "@/components/Telemetry/PersonalLists/PersonalListNetworkObjectMapMarker.vue";
import PersonalListNetworkObjectMapPopup
  from "@/components/Telemetry/PersonalLists/PersonalListNetworkObjectMapPopup.vue";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";

export default {
  name: "PersonalListMap",
  components: {BaseMap},
  props: {
    networkObjects: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState({
      baseLayers: state => state.baseLayers.baseLayers,
      layers: state => state.baseMap.layers,
      zoom: state => state.baseMap.zoom,
      center: state => state.baseMap.center
    }),
    createMarkers() {
      const ObjectIconClass = Vue.extend(PersonalListNetworkObjectMapMarker);
      const ObjectPopupClass = Vue.extend(PersonalListNetworkObjectMapPopup);

      return this.networkObjects.map(object => {
        const iconInstance = new ObjectIconClass();
        iconInstance.typeGroup = object.typeGroupName;
        const icon = iconInstance.$mount();
        const iconHtml = icon.$el.outerHTML;

        const popupInstance = new ObjectPopupClass();
        popupInstance.networkObject = object;
        const popup = popupInstance.$mount();

        const marker = new Marker({
          coords: object.coordinates,
          icon: new Icon({
            type: 'div_icon',
            size: [36, 42],
            anchor: [18, 21],
            html: iconHtml
          }),
          popup_content: popup.$el
        })

        icon.$destroy();
        popup.$destroy();

        return marker;
      })
    }
  }
}
</script>

<style scoped>

</style>