<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--user">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Имя</div>
        <div class="base-table__header-cell">E-mail</div>
        <div class="base-table__header-cell">Структурное подразделение</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="user in usersList"
           :key="user.id"
           @click="$emit('show-user-modal', user.id)"
           class="base-table__row base-table__column--user">
        <div class="base-table__column">
          <div class="base-table__text">{{ user.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ user.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ user.email }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ user.structuralUnit.name }}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click.stop="$emit('update-user', user)">
              <icon-pencil class="base-table__short-info--p-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPencil from "@/components/Icons/IconPencil.vue";

export default {
  name: "UsersList",
  components: {IconPencil},
  props: {
    usersList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.base-table__column--user {
  grid-template-columns: 1fr 3fr 3fr 3fr 1fr;
}
</style>
