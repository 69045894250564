<template>
  <div class="additional-option-wrapper"
       :class="{'always-open': isCollapseVisible}">
    <div class="additional-option">
      <div class="additional-option__icon">
        <icon-drop/>
      </div>
      <div class="additional-option__name">
        <span>Расход воды</span>
        <span class="additional-option__quantity">{{ waterFlows.length }}</span>
      </div>
      <div class="additional-option__buttons">
        <button
          v-show="isActionsVisible"
          class="additional-option__add"
          type="button"
          @click="showModal()">
          <icon-plus/>
        </button>
        <button
          @click="isCollapseVisible = !isCollapseVisible"
          class="additional-option__toggle"
          :class="{'rotate': isCollapseVisible}">
          <icon-arrow-double-up/>
        </button>
      </div>
    </div>
    <div class="additional-options-list">
      <div v-for="waterFlow in waterFlows"
           :key="waterFlow.id"
           class="additional-options-list__inner">
        <div class="added-item added-item--flex">
          <div class="added-item__info">
            <div class="added-item__name">Объём: {{ waterFlow.volume }}м<sup>3</sup></div>
            <div class="added-item__additional">
              Дата расхода: {{ formatDate(waterFlow.releasedAt) }}
            </div>
          </div>
          <div class="added-item__right">
            <span v-if="waterFlow.comment"
                  class="base-table__short-info base-table__short-info--mr-0">
              <icon-comment class="base-table__short-info-icon"/>
              <span class="base-table__short-info-counter">1</span>

              <div class="base-table__short-info-tooltip">
                <div class="base-table__short-info-tooltip-inner">
                  {{ waterFlow.comment }}
                </div>
              </div>
            </span>
            <icon-trash
              v-show="isActionsVisible"
              @click="deleteWaterFlow(waterFlow.id)"
              class="svg added-item__tool"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPlus from "@/components/Icons/IconPlus";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp";
import IconTrash from "@/components/Icons/IconTrash";
import {showAlert, showToast} from "@/utils/notification";
import WaterFlowsForm from "@/components/ServiceDesk/Tasks/TaskComponents/WaterFlows/WaterFlowsForm";
import IconDrop from "@/components/Icons/IconDrop";
import IconComment from "@/components/Icons/IconComment.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {formatDate} from "@/utils/filters";

export default {
  name: "WaterFlowsList",
  components: {IconComment, IconDrop, IconTrash, IconArrowDoubleUp, IconPlus},
  props: {
    taskId: {
      type: Number,
      required: true
    },
    waterFlows: {
      type: Array,
      required: true
    },
    isActionsVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isCollapseVisible: false
    }
  },
  methods: {
    formatDate,
    deleteWaterFlow(id) {
      this.$store.dispatch('waterFlows/removeWaterFlow', {
        taskId: this.taskId,
        waterFlowId: id
      })
        .then(() => {
          this.$store.commit('waterFlows/DELETE_WATER_FLOW', id);
          showToast('Расход воды удалён', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    showModal() {
      this.$modal.show(
        WaterFlowsForm,
        {
          'task-id': this.taskId
        },
        {
          name: 'water-flow-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(600)
        }
      )
    }
  }
}
</script>

<style scoped>
</style>
