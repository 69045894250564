<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--resource">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell">Мастер</div>
        <div class="base-table__header-cell">Транспорт</div>
        <div class="base-table__header-cell">Подразделение</div>
        <div class="base-table__header-cell">Структурное подразделение</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="resource in resourcesList"
           :key="resource.id"
           class="base-table__row base-table__column--resource">
        <div class="base-table__column">
          <div class="base-table__text">{{ resource.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ resource.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{resource.master.name}}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{resource.transport.name}}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{resource.department.name}}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{resource.structuralUnit.name}}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click="$emit('update-resource', resource)">
              <icon-pencil class="base-table__short-info--p-2" />
            </div>
            <div @click="$emit('delete-resource', resource.id)">
              <icon-trash />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";

export default {
  name: "ResourcesList",
  components: {IconPencil, IconTrash},
  props: {
    resourcesList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.base-table__column--resource {
  grid-template-columns: 1fr repeat(5, 3fr) 1fr;
}
</style>