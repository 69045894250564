<template>
  <div class="page-wrapper">
    <base-panel
      title="Ресурсы"
      :is-add-button-visible="true"
      add-button-name="Добавить ресурс"
      @add-button-clicked="showPopup()"
    />

    <resources-list
      :resources-list="resourcesList"
      @update-resource="showPopup"
      @delete-resource="deleteResource"
    />

    <base-content-loaders v-if="loading && !resourcesList.length" />

    <base-no-data v-if="!loading && !resourcesList.length" />

    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      action="resources/getResourcesList"
      commit="resources/SET_PAGE"
    />
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import ResourcesList from "@/components/ServiceDesk/admin/Resources/ResourcesList.vue";
import {mapState} from "vuex";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import BasePaginate from "@/components/BasePaginate.vue";
import ResourcesForm from "@/components/ServiceDesk/admin/Resources/ResourcesForm.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {showToast, showAlert, showConfirmationModal} from "@/utils/notification";

export default {
  name: "ResourcesListPage",
  components: {BasePaginate, BaseNoData, BaseContentLoaders, ResourcesList, BasePanel},
  computed: {
    ...mapState({
      resourcesList: state => state.resources.resourcesList,
      loading: state => state.resources.loading,
      meta: state => state.resources.meta
    })
  },
  mounted() {
    this.$store.dispatch('user/getStructuralUniUsersList');
    this.$store.dispatch('autos/getAllAutos');
    this.$store.dispatch('departments/getAllDepartments');
    this.$store.dispatch('structuralUnits/getUserStructuralUnitsList');
    this.$store.dispatch('resources/getResourcesList');
  },
  methods: {
    showPopup(resource = {}) {
      this.$modal.show(
        ResourcesForm,
        {
          'resource': resource,
          'createResource': this.createResource,
          'updateResource': this.updateResource,
          'popupName': 'resources-form'
        },
        {
          name: 'resources-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    createResource(payload) {
      this.$store.dispatch('resources/createResource', payload)
        .then(() => {
          this.$store.dispatch('resources/getResourcesList');
          this.$modal.hide('resources-form');
          showToast('Ресурс добавлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateResource(resourceId, payload) {
      this.$store.dispatch('resources/updateResource', {
        resourceId, payload
      })
        .then(() => {
          this.$store.dispatch('resources/getResourcesList');
          this.$modal.hide('resources-form');
          showToast('Ресурс обновлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    deleteResource(resourceId) {
      showConfirmationModal('Вы уверены, что хотите удалить ресурс? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('resources/deleteResource', resourceId)
              .then(() => {
                this.$store.commit('resources/DELETE_RESOURCE', resourceId);
                showToast('Ресурс удален', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>