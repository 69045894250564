import {getError} from "@/utils/helpers";
import WorkService from "@/services/WorkService";

export const namespaced = true

export const state = {
    works: [],
    work: {},
    task_works: [],
    meta: {},
    links: null,
    loading: false,
    error: null,
    page: 1,
    workInfoLoading: false
}

export const mutations = {
    SET_WORKS(state, works) {
        state.works = works
    },
    SET_WORK(state, workId) {
        state.work = state.works.find(work => work.id === workId);
    },
    SET_TASK_WORKS(state, works) {
        state.task_works = works;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_WORK_INFO_LOADING(state, loading) {
        state.workInfoLoading = loading;
    }
}

export const actions = {
    async getWorkInfo({commit, dispatch}, workId) {
        commit("SET_WORK_INFO_LOADING", true);

        commit("SET_WORK", workId)
        await Promise.all([
            dispatch('getWorkConsumptionRates', workId)
        ])
            .finally(() => commit("SET_WORK_INFO_LOADING", false));
    },
    getWorks({commit, state}) {
        commit('SET_LOADING', true);
        WorkService.getWorks(state.page)
            .then(({works, meta, links}) => {
                commit("SET_WORKS", works);
                commit("SET_META", meta);
                commit("SET_LINKS", links)
            })
            .catch(error => commit("SET_ERROR", getError(error)))
            .finally(() => commit("SET_LOADING", false));
    },
    getAllWorks({commit}, params) {
        commit("SET_LOADING", true);
        WorkService.getAllWorks(params)
            .then(works => commit("SET_WORKS", works))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    createWork({commit}, payload) {
        return new Promise((resolve, reject) => {
            WorkService.createWork(payload)
                .then(() => resolve())
                .catch(error => {
                    reject(getError(error));
                    commit("SET_ERROR", getError(error));
                })
        })
    },
    updateWork({commit}, {work_id, payload}) {
        return new Promise((resolve, reject) => {
            WorkService.updateWork({work_id, payload})
                .then(() => resolve())
                .catch(error => {
                    reject(getError(error));
                    commit("SET_ERROR", getError(error));
                })
        })
    },
    deleteWork({commit}, work_id) {
        return new Promise((resolve, reject) => {
            WorkService.deleteWork(work_id)
                .then(() => resolve())
                .catch(error => {
                    reject(getError(error));
                    commit("SET_ERROR", getError(error));
                })
        })
    },
    getTaskWorks({commit}, task_id) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            WorkService.getTaskWorks(task_id)
                .then(taskWorks => {
                    commit("SET_TASK_WORKS", taskWorks);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
                .finally(() => commit("SET_LOADING", false));
        })
    }
}

export const getters = {
    getWorks: state => {
        return state.works
    },
    getTaskWorks: state => {
        return state.task_works;
    },
    getWork: state => work_id => {
        return state.works.find(work => work.id === parseInt(work_id));
    }
}
