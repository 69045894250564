<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div class="i-modal__inner">
        <div class="base-modal__left-side">
          <div class="base-modal-header">
            <span class="base-modal-title">Редактирование заявки</span>
          </div>
          <div class="base-modal-info">
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-info-circle/>
                <span>Проблема</span>
              </div>
              <div class="base-modal-info__value">
                <problem-category-selector
                  :category_id="payload.category_id"
                  :group="group_id"
                  @update:category_id="categoryId => payload.category_id = categoryId"
                  @update:group_id="groupId => group_id = groupId"/>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-clipboard/>
                <span>Основание</span>
              </div>
              <div class="base-modal-info__value">
                <input
                  type="text"
                  v-model="payload.based_on"
                  class="base-input base-input--bordered"
                  placeholder="Введите основание заявки"
                >
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-clock />
                <span>Плановый срок</span>
              </div>
              <div class="base-modal-info__value">
                <div class="range-date-picker">
                  <date-picker
                    v-model="payload.planned_start_date"
                    :default-value="defaultValue"
                    placeholder="Начало"
                    type="datetime"
                    :minuteStep="10"
                    format="DD.MM.YYYY HH:mm"
                    value-type="YYYY-MM-DD HH:mm"
                    class="base-date-picker"
                  />
                  <div class="range-date-picker__dash"/>
                  <date-picker
                    v-model="payload.planned_end_date"
                    :default-value="defaultValue"
                    placeholder="Окончание"
                    type="datetime"
                    :minuteStep="10"
                    format="DD.MM.YYYY HH:mm"
                    value-type="YYYY-MM-DD HH:mm"
                    class="base-date-picker"
                    :disabled-date="notBeforeStartDate"
                  />
                </div>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-pin/>
                <span>Адрес</span>
              </div>
              <div class="base-modal-info__value">
                <div class="vue-dadata-wrapper">
                  <dadata-suggestions
                    class="vue-dadata-input"
                    type="ADDRESS"
                    @change="setAddress"
                    placeholder="Начните вводить адрес"
                    v-model="address"
                  />
                </div>
              </div>
            </div>
            <div class="base-modal-info__item--border-bottom">
              <create-issue-modal-map
                :issue-coords="markerCoords"
                @update:coords="({lat, lng}) => {
                  payload.address_lat = lat;
                  payload.address_lon = lng;
                }"/>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-comment/>
                <span>Комментарий</span>
              </div>
              <div class="base-modal-info__value">
                <textarea
                  type="text"
                  v-model="payload.description"
                  class="base-textarea"
                  placeholder="Напишите комментарий"
                />
              </div>
            </div>
            <div class="base-modal-btn-wp create-issue-buttons">
              <div @click="submitForm()"
                   class="button button--mode-primary">
                <span>Сохранить</span>
              </div>
              <div @click="$store.dispatch('baseSideModal/goBack')"
                   class="button button--mode-outline">
                <span>Отменить</span>
              </div>
            </div>
          </div>
        </div>
        <div class="i-modal-btn-wp i-modal-btn-wp--back">
          <div class="task-modal__mobile-actions">
            <button @click="$store.dispatch('baseSideModal/goBack')"
                    class="button button--mode-outline task-modal__mobile-back">
              <icon-arrow-left/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import ProblemCategorySelector from "@/components/ServiceDesk/Issues/ProblemCategorySelector.vue";
import IconComment from "@/components/Icons/IconComment.vue";
import IconInfoCircle from "@/components/Icons/IconInfoCircle.vue";
import IconClock from "@/components/Icons/IconClock.vue";
import IconClipboard from "@/components/Icons/IconClipboard.vue";
import CreateIssueModalMap from "@/components/ServiceDesk/Issues/CreateIssueModalMap.vue";
import IconPin from "@/components/Icons/IconPin.vue";
import {mapGetters, mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import DadataSuggestions from "vue-dadata-suggestions";
import Vue from "vue";
import DatePicker from "vue2-datepicker";

export default {
  name: "IssueUpdateModalContent",
  components: {
    IconPin,
    CreateIssueModalMap,
    IconClipboard,
    IconClock,
    IconInfoCircle,
    IconComment,
    ProblemCategorySelector,
    IconArrowLeft,
    DatePicker
  },
  data() {
    return {
      payload: {
        category_id: null,
        based_on: null,
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null,
        address_lat: null,
        address_lon: null,
        description: null,
        planned_start_date: null,
        planned_end_date: null
      },
      group_id: null,
      address: '',
      defaultValue: new Date(),
      markerCoords: [0, 0]
    }
  },
  computed: {
    ...mapState({
      issue: state => state.baseSideModal.componentPropsData
    }),
    ...mapGetters({
      dadataLocation: 'projectConfig/getProjectLocation'
    })
  },
  mounted() {
    Vue.use(DadataSuggestions, {
      token: process.env.VUE_APP_DADATA_API_KEY,
      constraints: {
        locations: this.dadataLocation
      }
    });

    const {category, based_on, address, description, planned_start_at, planned_end_at} = this.issue;
    this.payload.category_id = category.id;
    this.payload.based_on = based_on;
    this.payload.address_region = address.region;
    this.payload.address_city = address.city;
    this.payload.address_settlement = address.settlement;
    this.payload.address_street = address.street;
    this.payload.address_house = address.house;
    this.payload.address_block = address.block;
    this.payload.address_flat = address.flat;
    const [lat, lon] = this.issue.coordinates;
    this.payload.address_lat = lat;
    this.payload.address_lon = lon;
    this.payload.description = description;
    this.payload.planned_start_date = planned_start_at;
    this.payload.planned_end_date = planned_end_at;

    this.markerCoords = [lat, lon];
    this.$store.commit('baseMap/SET_CENTER_ZOOM', {
      center: [lat, lon],
      zoom: 17
    });
    this.group_id = category.group_id;
    this.address = this.issue.getFullAddress();
  },
  methods: {
    submitForm() {
      this.$store.dispatch('issue/updateIssue', {
        issue_id: this.issue.id,
        payload: this.payload
      })
        .then(() => {
          this.$store.dispatch('baseSideModal/goBack');
          showToast('Заявка обновлена', 'success')
        })
        .catch(error => showAlert('Ошибка', error))
    },
    setAddress(i) {
      if (i.data) {
        this.payload.address_region = i.data.region;
        this.payload.address_city = i.data.city;
        this.payload.address_settlement = i.data.settlement_with_type;
        this.payload.address_street = i.data.street_with_type;
        this.payload.address_house = i.data.house;
        this.payload.address_block = i.data.block;
        this.payload.address_flat = i.data.flat;

        if (!this.payload.address_lat && !this.payload.address_lon) {
          this.payload.address_lat = parseFloat(i.data.geo_lat);
          this.payload.address_lon = parseFloat(i.data.geo_lon);

          this.$root.$emit('fit-bounds-map', {
            coords: [parseFloat(i.data.geo_lat), parseFloat(i.data.geo_lon)],
            qc_geo: i.data.qc_geo
          })
        }
      }
    },
    notBeforeStartDate(date) {
      let startDate = new Date(this.payload.planned_start_date);
      startDate.setDate(startDate.getDate() - 1);
      return date < startDate;
    }
  }
}
</script>

<style lang="scss" scoped>
.create-issue-buttons {
  display: flex;
  gap: 12px;

  & .button {
    flex: 1;
  }
}
</style>