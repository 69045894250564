<template>
  <div class="personal-list">
    <base-functional-line @search-change="event => this.query = event.target.value">
      <div class="functional-line__view-buttons">
        <div
          @click="isListView = true"
          :class="{'active': isListView}">
          <icon-row-vertical/>
        </div>
        <div
          @click="isListView = false"
          :class="{'active': !isListView}">
          <icon-map/>
        </div>
      </div>
    </base-functional-line>

    <div
      v-if="isListView"
      class="personal-list__table">
      <div class="personal-list__table-caption">
        <div class="personal-list__objects-count">Всего объектов: {{ personalList.objectsList.length }}</div>
        <div
          @click="$emit('update-personal-list', personalList)"
          class="personal-list__actions">Редактировать список</div>
      </div>

      <div class="personal-list__table-wrapper">
        <div
          v-for="object in filteredNetworkObjects"
          :key="object.id"
          @click="$emit('show-network-object-modal', object.id)"
          class="personal-list__object">
          <div class="personal-list__object-name">
            <component :is="iconComponentMap[object.typeGroupName]"/>
            <span>{{ object.name }}</span>
          </div>
          <div class="personal-list__equipments-list">
            <div
              v-for="equipment in object.equipments"
              :key="equipment.id"
              class="personal-list__equipment-card">
              <div class="personal-list__equipment-name">
                {{equipment.name}}
              </div>
              <div class="personal-list__equipment-parameters">
                <personal-list-parameter
                  v-for="parameter in equipment.parameters"
                  :key="parameter.id"
                  :parameter="parameter"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="personal-list__map reset-leaflet-popup-styles">
      <personal-list-map :network-objects="filteredNetworkObjects" />
    </div>
  </div>
</template>

<script>
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import IconMap from "@/components/Icons/IconMap.vue";
import IconRowVertical from "@/components/Icons/IconRowVertical.vue";
import {mapGetters, mapState} from "vuex";
import PersonalListParameter from "@/components/Telemetry/PersonalLists/PersonalListParameter.vue";
import PersonalListMap from "@/components/Telemetry/PersonalLists/PersonalListMap.vue";
import NetworkObject from "@/models/telemetry/NetworkObject";

export default {
  name: "PersonalList",
  components: {PersonalListMap, PersonalListParameter, IconRowVertical, IconMap, BaseFunctionalLine},
  props: {
    personalList: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isListView: true,
      query: ''
    }
  },
  computed: {
    ...mapGetters({
      networkObject: 'networkObjects/getNetworkObject',
      networkEquipment: 'networkEquipments/getNetworkEquipmentById'
    }),
    ...mapState({
      networkObjectsList: state => state.networkObjects.networkObjects,
      networkEquipmentsList: state => state.networkEquipments.networkEquipments
    }),
    iconComponentMap: () => NetworkObject.getIconComponentsMap(),
    filteredNetworkObjects() {
      return this.query.length ? this.formattedPersonalList.objectsList.filter(object => {
        return object.name.toLowerCase().includes(this.query.toLowerCase())
      }) : this.formattedPersonalList.objectsList;
    },
    formattedPersonalList() {
      if (this.networkObjectsList.length && this.networkEquipmentsList.length) {
        return {
          ...this.personalList,
          objectsList: this.personalList.objectsList.map(objectStructure => {
            const networkObject = this.networkObject(objectStructure.object_id);
            return {
              id: networkObject.id,
              name: networkObject.name,
              coordinates: networkObject.coordinates,
              typeGroupName: networkObject._type.groupName,
              equipments: objectStructure.equipments.map(equipmentStructure => {
                const equipment = this.networkEquipment(equipmentStructure.equipment_id);
                return {
                  id: equipment.id,
                  name: equipment.name,
                  parameters: equipmentStructure.parameters.map(parameterStructure => {
                    return equipment.parameters
                      .find(parameter => parameter.id === parameterStructure.parameter_id);
                  })
                }
              })
            }
          })
        }
      } else return {objectsList: []}
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/helpers/mixins";

.personal-list {
  &__table {
    &-caption {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 20px;
      margin-top: 32px;

      @include font-style(400, 14px, 16px, var(--text-secondary));
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  &__actions {
    cursor: pointer;
  }

  &__object {
    display: flex;
    gap: 12px;
    padding: 4px;

    border-radius: 8px;
    border: 1px solid var(--border-main);

    background-color: var(--panel-bg);

    cursor: pointer;

    &-name {
      display: flex;
      align-items: center;
      gap: 8px;

      padding-left: 8px;
      min-width: 230px;
      max-width: 230px;

      @include font-style(600, 14px, 16px, var(--text-primary));

      & svg {
        min-width: 24px;
      }
    }
  }

  &__equipments-list {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  &__equipment-card {
    display: flex;
    flex-direction: column;
    gap: 10px;

    padding: 8px;

    border-radius: 8px;
    background-color: var(--panel);
  }

  &__equipment-name {
    @include font-style(400, 12px, 14px, var(--text-primary));
  }

  &__equipment-parameters {
    display: flex;
    gap: 10px;
  }

  &__map {
    height: calc(100vh - 280px);
  }
}
</style>